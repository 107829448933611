import React, { useState, useContext, useEffect, useMemo } from 'react'
import { FlexView, Card, Button } from '../components/common'
import { Input, Select } from '../components/form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { withColor } from 'utils/styled-decorators'
import { useHistory } from 'react-router-dom'
import { UserContext } from 'stores/UserStore'

const CardTitle = styled.div`
  ${withColor('gray')}
  font-size: ${({ theme }) => theme.fontSizes.title};
  font-weight: 700;
  margin-left: 10px;
  margin-top: 3px;  
`

const ReadInput = styled(Input)`
  background-color: ${({ theme }) => theme.colors.whitesmoke}
`

const UpdateProfile = () => {
  
  const DEFAULT_URL = "smart/edit-user-profile"
 
  const history = useHistory()
  const { t } = useTranslation()

  const { currentUser, setCurrentUser, updateProfile } = useContext(UserContext)
  const [formState, setFormState] = useState({...currentUser})
  const [loading, setLoading] = useState(false)
  const { registryLog } = useContext(UserContext)

  useEffect(() => {
    registryLog('Page In' , DEFAULT_URL)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
 
  
  const  onSaveClick = async () => {
    registryLog('Save' , DEFAULT_URL)
    setLoading(true)
    const result = await updateProfile(formState)
    if (result === 'SUCCESS') {
      setCurrentUser(formState)
      history.goBack()
    }
    setLoading(false)
  }

  const optionsLanguages = useMemo(()=> {
    return [
      {label: t('update-profile.language-pt-br'), value: 'pt-BR'},
      {label: t('update-profile.language-en'), value: 'en'},
      {label: t('update-profile.language-es'), value: 'es'}
    ]}, [t])

  const handleInputChange = (event) => {
    event.persist()
    setFormState(currentState => ({
      ...currentState,
      [event.target.name]: event.target.value
    }))
  }  
  
  const handleSelectInput = name => value => {
    setFormState(currentState => ({
      ...currentState,
      [name]: value
    }))
  }  

  return <FlexView flex="1" alignSelf="stretch" alignItems="center" backgroundColor="background" padding="40px">
    <Card width="50%" backgroundColor="transparent" alignItems="stretch" justifyContent="center">
      <FlexView alignItems="center" justifyContent="center">
        <CardTitle>{t('update-profile.pageTitle')}</CardTitle>
      </FlexView>      
      <FlexView padding="1px 8px 0px">
        <ReadInput margin="0px" label={t('update-profile.email')} width="100%" readOnly={true} name="email" value={formState.email} />
      </FlexView>      
      <FlexView padding="1px 8px 0px">
        <ReadInput margin="0px" label={t('update-profile.name')} width="100%" readOnly={true} name="name" value={formState.name} />
      </FlexView>      
      <FlexView padding="1px 8px 0px" flexDirection="row">
        <Select margin="0px" label={t('update-profile.language')} options={optionsLanguages} onChange={handleSelectInput('language')} value={formState.language} width="100%"  />
        <Input margin="0px 0px 0px 10px" label={t('update-profile.phoneNumber')} width="100%" onChange={handleInputChange} name="phoneNumber" value={formState.phoneNumber} />
      </FlexView>
      <FlexView padding="1px 8px 0px">
        <Input margin="0px" label={t('update-profile.location')} width="100%" onChange={handleInputChange} name="location" value={formState.location} />
      </FlexView>                  
      <FlexView padding="1px 8px 0px">
        <Input margin="0px" label={t('update-profile.position')} width="100%" onChange={handleInputChange} name="position" value={formState.position} />
      </FlexView>                  
      <FlexView padding="1px 8px 0px">
      </FlexView>                  
      <FlexView margin="25px 8px 0px" flexDirection="row" alignItems="flex-end" justifyContent="flex-end">
        <Button alignSelf="flex-end" backgroundColor="secondary" color="white" style={{marginRight: '10px'}} fontSize="big" onClick={() => history.goBack()} >
          {t('update-profile.cancel')}
        </Button>
        <Button isLoading={loading} alignSelf="flex-end" backgroundColor="primary" color="white" fontSize="big" onClick={onSaveClick} >
          {t('update-profile.save')}
        </Button>
      </FlexView>
    </Card>
  </FlexView>
}

export default UpdateProfile