import React, { useContext } from 'react'
import { UserContext } from '../../stores/UserStore'
import { AppContext } from '../../stores/AppStore'
import Header from '../../components/layout/Header'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import AppLogo from 'assets/images/logo.png'

const AppHeader = () => {
  const history = useHistory()

  const { t } = useTranslation()
  const { isHeaderVisible, pageTitle } = useContext(AppContext)
  const { authState, signOut, currentUser } = useContext(UserContext)

  const userSettings = {
    username: currentUser ? currentUser.name : currentUser,
    options: [
      {
        key: 'version',
        label: 'v' + process.env.REACT_APP_VERSION,
        onClick: () => { }
      },
      {
        key: 'edit-profile',
        label: t('Edit Profile'),
        onClick: () => history.push('/update-profile'),
        divider: true
      },
      {
        key: 'sign-out',
        label: t('Sign Out'),
        onClick: signOut,
        divider: true
      }
    ]
  }

  const sideMenuSettings = {
    appLogoSrc: AppLogo,
    itemGroups: [
      {
        key: 'no-header',
        items: [
          {
            key: 'search',
            icon: 'apps',
            label: t('header.home'),
            onClick: () => window.location.href = process.env.REACT_APP_HOME_URL
          }
        ]
      }
    ]
  }

  const apps = currentUser && currentUser.applications.map(app => {
    return {
      key: app.id,
      label: app.label,
      iconSrc: app.icon,
      onClick: () => window.location.href = app.link
    }
  })

  const redirectToHome = () => window.location.href = process.env.REACT_APP_HOME_URL

  return isHeaderVisible && authState === 'SIGNED_IN' ?
    <Header
      title={pageTitle}
      sideMenuSettings={sideMenuSettings}
      // appIconSrc={LogoImg}
      onAppIconClick={redirectToHome}
      userSettings={userSettings}
      //TITULO DO PROJETO AQUI
      apps={apps} />
    : null
}

export default AppHeader