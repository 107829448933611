import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import _ from 'lodash'
import { Manager, Reference, Popper } from 'react-popper'
import { useTranslation } from 'react-i18next'
import { FlexView } from 'components/common'
import Cookies from 'js-cookie'
import BrazilFlag from 'assets/icons/brazil-flag.svg'
import UsaFlag from 'assets/icons/usa-flag.svg'
import EsFlag from 'assets/icons/es-flag.svg'
import { withMargin, withFontSize } from 'utils/styled-decorators'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  ${withMargin()}
  ${withFontSize('medium')}
`

const SelectBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  padding: 8px 16px;
  border: 2px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  outline: none;
  transition: all 0.2s;

  &:hover {
    box-shadow: 0px 0px 4px #aaa;
  }

  * {
    cursor: pointer;
  }

  ${({ noWrapper }) => noWrapper && css`
    border: none;
    padding: 8px;
    margin: -8px -8px -8px;
    transition: background-color 0.2s ease;

    &:hover {
      box-shadow: none;
      background-color: ${({ theme }) => theme.colors.offWhite};
    }
  `}
`

const Dropdown = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;
  transform: ${({ open }) => open ? 'rotate(180deg)' : 'rotate(0deg)'};

  svg {
    height: 10px;
    fill: ${({ theme }) => theme.colors.gray};
  }
`

const Options = styled.div`
  display: flex;
  visibility: ${({ open }) => open ? 'visible' : 'hidden'};
  opacity: ${({ open }) => open ? '1' : '0'};
  transform: ${({ popperTransform, open }) => `${popperTransform} ${open ? 'translateX(0)' : 'scale(0.9)'} !important`};
  flex-direction: column;
  margin: 5px 0px;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px #CCC;
  max-height: 250px;
  overflow-y: auto;
  border-radius: 8px;
  z-index: 999;
  transition: all 0.2s linear;
`

const Option = styled.div`
  display: flex;
  padding: 10px 20px;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Roboto';
  user-select: none;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkGray};
  transition: background-color 0.3s;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: #EEEEEE;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:not(:first-child) {
    border-top: 1px solid #DDD;
  }

  span {

  }
`

const Flag = styled.img`
  height: 1rem;
`

const Label = styled.span`
  margin-left: 10px;
  font-family: 'Roboto';
  font-weight: normal;
`

const options = [
  {
    value: 'pt-BR',
    label: 'Português',
    logo: BrazilFlag
  },
  {
    value: 'en',
    label: 'English',
    logo: UsaFlag
  },
  {
    value: 'es',
    label: 'Spanish',
    logo: EsFlag
  },
  
]

const OptionsPopper = React.forwardRef(({ style, scheduleUpdate, placement, isOpen, renderOptions }, ref) => {
  useEffect(() => {
    scheduleUpdate()
  }, [isOpen, scheduleUpdate])

  return <Options ref={ref} style={style} data-placement={placement} open={isOpen} popperTransform={style.transform}>
    {renderOptions()}
  </Options>
})

const LanguageSelect = ({ noLabel, noWrapper, ...rest}) => {
  const node = useRef(null)
  const [isOpen, setDropdown] = useState(false)
  const { i18n } = useTranslation()

  const toggleSelect = () => setDropdown(!isOpen)

  const changeLanguage = (lng) => () => {
    i18n.changeLanguage(lng)
    localStorage.setItem('language', lng)
    Cookies.set('language', lng, { expires: 365, domain: process.env.REACT_APP_DOMAIN })
    toggleSelect()
  }

  const renderOptions = () => {
    return _.map(options, ({ value, label, logo }) =>
    <Option key={value} value={value} onClick={changeLanguage(value)}>
      <Flag src={logo} alt={label} />
      {!noLabel && <Label>{label}</Label>}
    </Option>
    )
  }

  const getPlaceholder = () => {
    const selectedOption = _.find(options, { value: i18n.language })
    return selectedOption && <FlexView flexDirection="row" justifyContent="space-between" alignItems="center" fontSize={rest.fontSize}>
      <Flag src={selectedOption.logo} alt={selectedOption.label} />
      {!noLabel && <Label>{selectedOption.label}</Label>}
    </FlexView>
  }

  const handleOutsideClick = (e) => {
    node && node.current && !node.current.contains(e.target) && setDropdown(false)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick)
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick)
    }
  }, [])

  return (
    <Manager>
      <Wrapper ref={node} {...rest}>
        <Reference>
          {({ ref }) => (
            <SelectBox ref={ref} onClick={toggleSelect} noWrapper={noWrapper}>
              {getPlaceholder()}
              {!noLabel && <Dropdown open={isOpen}>
                <svg version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000">
                  <g><path d="M500,775.4L10,287.2l64.4-62.6L500,650.2l425.6-425.6l64.4,62.6L500,775.4z" /></g>
                </svg>
              </Dropdown>}
            </SelectBox>
          )}
        </Reference>
        <Popper
          placement="bottom"
          modifiers={{
            preventOverflow: {
              enabled: true,
              boundariesElement: 'viewport'
            }
          }}
        >
          {({ ref, style, scheduleUpdate, placement }) => <OptionsPopper {...{ style, scheduleUpdate, placement, isOpen, renderOptions, ref }} />}
        </Popper>
      </Wrapper>
    </Manager>
  )
}

export default LanguageSelect