import React from 'react'
import ReactDOM from 'react-dom'
import './assets/css/variables.css'
import './index.css'
import './assets/css/toast.css'
import './assets/css/transitions.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import firebase from 'firebase/app'
import 'firebase/analytics'

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_apiKey,
    authDomain: process.env.REACT_APP_FIREBASE_authDomain,
    projectId: process.env.REACT_APP_FIREBASE_projectId,
    storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
    messagingSenderId:   process.env.REACT_APP_FIREBASE_messagingSenderId,
    appId: process.env.REACT_APP_FIREBASE_appId,
    measurementId: process.env.REACT_APP_FIREBASE_measurementId
}

if (process.env.NODE_ENV === 'production') {
    console.log = () => { }
    firebase.initializeApp(firebaseConfig)
    firebase.analytics()
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
