import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { UserContext } from 'stores/UserStore'
import LogoImage from 'assets/images/logo.svg'
import AppCard from 'containers/home/AppCard'
import InfoModal from 'components/home/InfoModal'
import { FlexView, Icon } from '../components/common'

import HelpLogo from '../assets/icons/apps/new-help-icon.png'
import testVallourecOnline from '../assets/icons/apps/vallourec-online.svg'

const Logo = styled.img`
  height: 220px;

  @media screen and (max-width: 1680px) {
    height: 150px;
  }

  @media screen and (max-width: 1280px) {
    height: 150px;
  }

  @media screen and (max-width: 1080px) {
    height: 150px;
  }

  @media screen and (max-width: 880px) {
    height: 100px;
  }

  @media screen and (max-width: 680px) {
    height: 64px;
  }

  @media screen and (max-width: 480px) {
    height: 50px;
  }
`

const Grid = styled(FlexView)`
  display: grid;
  grid-template-columns: repeat(6, auto);
  margin: 16px 0px 128px 0px;

  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(6, auto);
  }

  @media screen and (max-width: 1080px) {
    grid-template-columns: repeat(5, auto);
  }

  @media screen and (max-width: 880px) {
    grid-template-columns: repeat(4, auto);
  }

  @media screen and (max-width: 680px) {
    grid-template-columns: repeat(4, auto);
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(3, auto);
  }
`

const InfoButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  transition: opacity 0.2s;
`

const Wrapper = styled(FlexView)`
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  padding: 16px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.boxShadows.low};
  margin: 4px !important;
  transition: all 0.2s;

  width: 10rem;
  min-height: 5rem;
  img {
    height: 2.8rem;
    width: 2.8rem;
  }
  font-size: 0.8rem;

  & > ${FlexView} {
    transition: all 0.2s;
    filter: grayscale(100%);
    cursor: pointer;
    user-select: none;
    font-size: 0.9rem;
  }

  ${InfoButton} {
    opacity: 0;
  }

  &:hover {
    & > ${FlexView} {
      filter: grayscale(0%);
    }

    box-shadow: ${({ theme }) => theme.boxShadows.small};

    ${InfoButton} {
      opacity: 1;
    }
  }

  @media screen and (max-width: 1280px) {
    width: 9rem;
    min-height: 5rem;
    img {
      height: 2.8rem;
      width: 2.8rem;
    }
    & > ${FlexView} {
      font-size: 0.8rem;
    }
  }

  @media screen and (max-width: 1080px) {
    width: 8rem;
    min-height: 4rem;
    img {
      height: 2.6rem;
      width: 2.6rem;
    }
    & > ${FlexView} {
      font-size: 0.7rem;
    }
  }

  @media screen and (max-width: 880px) {
    width: 7rem;
    min-height: 3rem;
    img {
      height: 2.2rem;
      width: 2.2rem;
    }
    & > ${FlexView} {
      font-size: 0.6rem;
    }
  }

  @media screen and (max-width: 680px) {
    width: 5.5rem;
    min-height: 1.5rem;
    img {
      height: 2rem;
      width: 2rem;
    }
    & > ${FlexView} {
      font-size: 0.5rem;
    }
  }

  @media screen and (max-width: 480px) {
    width: 5rem;
    min-height: 1rem;
    img {
      height: 1.8rem;
      width: 1.8rem;
    }
    & > ${FlexView} {
      font-size: 0.48rem;
    }
  }
`

const WrapperVallOnline = styled.a`
  text-decoration: none;
  display: flex;
  flex-direction: column;

  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  padding: 16px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.boxShadows.low};
  margin: 4px !important;
  transition: all 0.2s;

  width: 10rem;
  min-height: 5rem;
  img {
    height: 2.8rem;
    width: 2.8rem;
  }
  font-size: 0.8rem;

  & > ${FlexView} {
    transition: all 0.2s;
    filter: grayscale(100%);
    cursor: pointer;
    user-select: none;
    font-size: 0.9rem;
  }

  ${InfoButton} {
    opacity: 0;
  }

  &:hover {
    & > ${FlexView} {
      filter: grayscale(0%);
    }

    box-shadow: ${({ theme }) => theme.boxShadows.small};

    ${InfoButton} {
      opacity: 1;
    }
  }

  @media screen and (max-width: 1280px) {
    width: 9rem;
    min-height: 5rem;
    img {
      height: 2.8rem;
      width: 2.8rem;
    }
    & > ${FlexView} {
      font-size: 0.8rem;
    }
  }

  @media screen and (max-width: 1080px) {
    width: 8rem;
    min-height: 4rem;
    img {
      height: 2.6rem;
      width: 2.6rem;
    }
    & > ${FlexView} {
      font-size: 0.7rem;
    }
  }

  @media screen and (max-width: 880px) {
    width: 7rem;
    min-height: 3rem;
    img {
      height: 2.2rem;
      width: 2.2rem;
    }
    & > ${FlexView} {
      font-size: 0.6rem;
    }
  }

  @media screen and (max-width: 680px) {
    width: 5.5rem;
    min-height: 1.5rem;
    img {
      height: 2rem;
      width: 2rem;
    }
    & > ${FlexView} {
      font-size: 0.5rem;
    }
  }

  @media screen and (max-width: 480px) {
    width: 5rem;
    min-height: 1rem;
    img {
      height: 1.8rem;
      width: 1.8rem;
    }
    & > ${FlexView} {
      font-size: 0.48rem;
    }
  }
`

const Home = () => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalText, setModalText] = useState('')
  const { registryLog } = useContext(UserContext)
  const DEFAULT_URL = 'smart/home'
  const history = useHistory()

  const toggleModal = () => setModalOpen((isModalOpen) => !isModalOpen)

  useEffect(() => {
    registryLog('Page In', DEFAULT_URL)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onCardInfoClick = (title, text) => {
    setModalTitle(title)
    setModalText(text)
    setModalOpen(true)
  }

  const onCardHelpClick = () => {
    history.push('/help')
  }


  const onInfoClick = (title, text) => {
    setModalOpen(true)
    setModalTitle(title)
    setModalText(text)
  }

  return (
    <FlexView
      flex="1"
      alignSelf="stretch"
      alignItems="center"
      backgroundColor="background"
      padding="20px 40px 40px 40px"
    >
      <Logo src={LogoImage} alt="Logo" />
      <Grid
        width="100%"
        backgroundColor="transparent"
        alignItems="stretch"
        justifyContent="center"
      >
        <AppCard
          appId="TYM"
          text={t('apps.tym.text')}
          onCardInfoClick={onCardInfoClick}
        />
        <AppCard
          appId="TLH"
          text={t('apps.tlh.text')}
          onCardInfoClick={onCardInfoClick}
        />
        <AppCard
          appId="TRACEABILITY"
          text={t('apps.traceability.text')}
          onCardInfoClick={onCardInfoClick}
        />
        <AppCard
          appId="COLLABORATIVE_PLANNING"
          text={t('apps.collaborative-planning.text')}
          onCardInfoClick={onCardInfoClick}
        />
        <AppCard
          appId="PROBABILISTIC_DESIGN"
          text={t('apps.probabilistic-design.text')}
          onCardInfoClick={onCardInfoClick}
        />
        <AppCard
          appId="DATABOOK"
          text={t('apps.digital-databook.text')}
          onCardInfoClick={onCardInfoClick}
        />
        <AppCard
          appId="RUNNING_EXPERT"
          text={t('apps.running-expert.text')}
          onCardInfoClick={onCardInfoClick}
        />
        <AppCard
          appId="STRING_DNA"
          text={t('apps.string-dna.text')}
          onCardInfoClick={onCardInfoClick}
        />
        <AppCard
          appId="PERFORMANCE_MAPPING"
          text={t('apps.performance-mapping.text')}
          onCardInfoClick={onCardInfoClick}
        />
        <AppCard
          appId="INVENTORY_REACT"
          text={t('apps.tym.text')}
          onCardInfoClick={onCardInfoClick}
        />
        <AppCard
          appId="REPORTS"
          text={t('apps.reports.text')}
          onCardInfoClick={onCardInfoClick}
        />
        <AppCard
          appId="RFID_PETROBRAS"
          text={t('apps.rfid.text')}
          onCardInfoClick={onCardInfoClick}
        />
        {/* <AppCard appId="HELP" text={t('apps.home.help')} onCardInfoClick={onCardInfoClick} />
      <AppCard appId="HELP" text={t('apps.tym.help')} onCardInfoClick={onCardInfoClick} /> */}

        <WrapperVallOnline target="_blank" href={!modalOpen ? "https://vallourec.outsystemsenterprise.com/VallourecOnlineSA/" : null}>
          <InfoButton styled={{ zIndex: '100 !important', position: 'fixed' }}>
            <Icon name="info" width="24px" height="24px" onClick={() => onInfoClick('Vallourec Online', t('apps.vallourec-online.text'))} />
          </InfoButton>
          <img src={testVallourecOnline} alt="Vallourec Online" />
          <FlexView fontWeight="bold" fontSize="big" color="primary" margin="16px 0px 0px 0px">Vallourec Online</FlexView>
        </WrapperVallOnline>

        <Wrapper>
          <InfoButton styled={{ zIndex: '100 !important' }}>
            <Icon name="info" width="24px" height="24px" onClick={() => onInfoClick('Help', t('apps.help.text'))} />
          </InfoButton>
          <img src={HelpLogo} alt="help" onClick={onCardHelpClick} />
          <FlexView fontWeight="bold" fontSize="big" color="primary" margin="16px 0px 0px 0px" onClick={onCardHelpClick}>Help</FlexView>
        </Wrapper>

        {/* Temporary: Inventory angola must be remove as soon as old inventory is migrated to new platform */}
        <AppCard
          appId="INVENTORY_ANGOLA"
          text={t('apps.tym.text')}
          onCardInfoClick={onCardInfoClick}
        />
      </Grid>
      <InfoModal
        isOpen={modalOpen}
        onOutsideClick={toggleModal}
        title={modalTitle}
        text={modalText}
      ></InfoModal>
    </FlexView>
  )
}

export default Home
