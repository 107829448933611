import React from 'react'
import { FlexView } from './components/common'
import Header from './containers/layout/Header'
import Home from './pages/Home'
import UpdateProfile from './pages/UpdateProfile'
import Help from './pages/Help'
import Login from './pages/Login'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PrivateRoute from './containers/router/PrivateRoute'

const Router = () => {

  return (
    <FlexView height="100vh" position="fixed" width="100%" >
      <BrowserRouter>
        <Header />
        <Switch>
          <Route path='/login'>
            <Login />
          </Route>
          <PrivateRoute exact path='/'>
            <Home />
          </PrivateRoute>
          <PrivateRoute exact path='/update-profile'>
            <UpdateProfile />
          </PrivateRoute>
          <PrivateRoute exact path='/help'>
            <Help />
          </PrivateRoute>
        </Switch>
      </BrowserRouter>
    </FlexView>
  )
}

export default Router