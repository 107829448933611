import i18n from 'i18next'
import moment from 'moment'
import 'moment/locale/pt-br'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './en.json'
import ptBR from './pt-br.json'
import es from './es.json'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: en
  },
  'pt-BR': {
	  translation: ptBR
  },
  'es': {
	  translation: es
  }
}

// passes i18n down to react-i18next
i18n.use(initReactI18next).use(LanguageDetector).init({
  resources,
  fallbackLng: ['pt-BR', 'en', 'es'],
  whitelist: ['pt-BR', 'en', 'es'],
  interpolation: {
    escapeValue: false // react already safes from xss
  },
  detection: {
    lookupCookie: 'language',
  }
})

i18n.on('languageChanged', function(lng) {
  moment.locale(lng)
})

export default i18n