import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import Cookies from 'js-cookie'
import axios from 'axios'
import { LoadingOverlay } from 'components/common'
import { descompressResponse } from 'utils/decompress'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const initialState = {
  user: null,
  accessToken: '',
  idToken: ''
}

export const UserContext = React.createContext(initialState)

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)
  const [settings, setSettings] = useState(null)
  const [authState, setAuthState] = useState(null)
  const [accessToken, setAccessToken] = useState('')
  const [idToken, setIdToken] = useState('')
  const [isLoading, setLoading] = useState(true)
  const { t } = useTranslation()

  const signOut = async () => {
    try {
      await Auth.signOut()
      setUser(null)
      setAccessToken('')
      setIdToken('')
      setAuthState(null)
    }
    catch (e) {
      console.log(e)
    }
  }

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': idToken + '.' + btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
    }
  })

  const authApi = axios.create({
    baseURL: process.env.REACT_APP_AUTH_URL,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': idToken + '.' + btoa(JSON.stringify({ "id": process.env.REACT_APP_ID }))
    }
  })

  const logInstance = axios.create({
    baseURL: process.env.REACT_APP_AWS_CLOUDWATCH_ENDPOINT,
  })

  const registryLog = (click, url)  => {
    new Promise((resolve, reject) => {
      console.log(process.env.REACT_APP_AWS_CLOUDWATCH_ENDPOINT)
      if (user) {
        logInstance.post(
          '/access-ctrl/log',
          {
            type: 'access',
            user: {
              userId: user.attributes.email
            },
            functionality: url,
            application: 'smart',
            message: click
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        ).then(response => {
          if (response.status === 200) {
            console.log('Log salvo')
          }
          else {
            reject(new Error('Couldn\'t retrieve data'))
          }
        })
          .catch(error => {
            reject(error)
          })
      }
    })
  
  }


  const getUserApps = (token) => {
    return new Promise((resolve, reject) => {
      if (!currentUser) {
        const headerToken = { 'Authorization': token + '.' + btoa(JSON.stringify({ "id": process.env.REACT_APP_ID })) }
        authApi.post('/user/apps', {}, { headers: headerToken })
          .then((response) => {
            const responseData = response.data ? response.data : response
            const data = descompressResponse(responseData)
            setCurrentUser(data.user)
            setSettings(data.rootSettings)
            console.log(data)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  const updateProfile = async (user) => {
    const params = {update: user}
    return new Promise((resolve, reject) => {
      const headerToken = { 'Authorization': idToken }
      authApi.post('/user/update-profile', params, { headers: headerToken })
        .then((response) => {
          resolve(response.data.body)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const helpClientEmail = async (formState) => {
    console.log(process.env.REACT_APP_AUTH_URL)
    const params = {formState}
    return new Promise((resolve, reject) => {
      const headerToken = { 'Authorization': idToken }
      authApi.post('/user/help-client-email', params, { headers: headerToken })
        .then((response) => {
          resolve(response.data.body)
          toast(t('help.success'))
        })
        .catch((error) => {
          toast(t('help.fail'))
          reject(error)
        })
    })
  }


  useEffect(() => {
    const hydrate = () => new Promise((resolve, reject) => {
      const shouldGetUser = (Cookies.get('rememberMe') === 'true' || Cookies.get('signedIn') === 'true')
      shouldGetUser ? Auth.currentUserPoolUser().then(async (user) => {
        if (user) {
          console.log(user)
          setUser(user)
          setIdToken(user.signInUserSession.idToken.jwtToken)
          setAccessToken(user.signInUserSession.accessToken.jwtToken)
          setAuthState('SIGNED_IN')
          await getUserApps(user.signInUserSession.idToken.jwtToken)
        }
        resolve()
      }).catch(err => {
        reject(err)
      }) : resolve()
    })

    hydrate().catch(err => {
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const renderStore = isLoading ?
    <LoadingOverlay visible={true} />
    :
    <UserContext.Provider value={{
      user,
      accessToken,
      idToken,
      authState,
      currentUser,
      setCurrentUser,
      settings,
      api,
      authApi,
      signOut,
      registryLog,
      updateProfile,
      helpClientEmail
    }}>
      {children}
    </UserContext.Provider>

  return renderStore
}
