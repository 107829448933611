import * as pako from 'pako'

export const descompressResponse = (response) => {
    let _body = response.body ? response.body : response
    const buffer = new Buffer(_body, 'base64')
    const inflated = pako.inflate(buffer)
    const result = JSON.parse(utf8ArrayToStr(inflated))
    return result
}

export const utf8ArrayToStr = (array) => {
    let out, i, len, c
    let char2, char3

    out = ""
    len = array.length
    i = 0
    while (i < len) {
        c = array[i++]
        switch (c >> 4) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                // 0xxxxxxx
                out += String.fromCharCode(c)
                break
            case 12:
            case 13:
                // 110x xxxx   10xx xxxx
                char2 = array[i++]
                out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F))
                break
            case 14:
                // 1110 xxxx  10xx xxxx  10xx xxxx
                char2 = array[i++]
                char3 = array[i++]
                out += String.fromCharCode(((c & 0x0F) << 12) |
                    ((char2 & 0x3F) << 6) |
                    ((char3 & 0x3F) << 0))
                break
            default:
        }
    }
    return out
}