import React, { useState } from 'react'

const initialState = {
  name: process.env.REACT_APP_NAME,
  subtitle: '',
  version: process.env.REACT_APP_VERSION
}

export const AppContext = React.createContext(initialState)

export const AppProvider = ({ children }) => {
  const [redirectUrl, setRedirectUrl] = useState('')
  const [pageTitle, setPageTitle] = useState(initialState.name)
  const [pageSubtitle, setPageSubtitle] = useState(initialState.subtitle)
  const [isHeaderVisible, setHeaderVisibility] = useState(true)

  return <AppContext.Provider value={{
    ...initialState,
    redirectUrl,
    pageTitle,
    pageSubtitle,
    isHeaderVisible,
    setRedirectUrl,
    setPageTitle,
    setPageSubtitle,
    setHeaderVisibility
  }}>
    {children}
  </AppContext.Provider>
}