import React, { useState, useContext, useEffect, useMemo } from 'react'
import { FlexView, Card, Button } from '../components/common'
import { Input, Select, TextArea } from '../components/form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { withColor, withBackgroundColor } from 'utils/styled-decorators'
import { useHistory } from 'react-router-dom'
import { UserContext } from 'stores/UserStore'
import { toast } from "react-toastify"

const CardTitle = styled.div` 
  ${withColor('gray')}
  font-size: ${({ theme }) => theme.fontSizes.title};
  font-weight: 700;
  margin-left: 10px;
  margin-top: 3px; 
    
`
const ReadInput = styled(Input)`
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
  font-weight: normal;
  background-color: ${({ theme }) => theme.colors.white} 
`

const HelpPage = styled(Card)`
  ${withBackgroundColor('#fff')}
  align-items: stretch;
  justify-content: center;
  margin:-10px 0px;
  width:50%; 
  padding: 40px;

  @media (max-height: 640px) {
    margin-top: -70px;
    transform: scale(0.8);
   }

  @media (max-height: 540px) {
    margin-top: -100px;
    transform: scale(0.7);
  }
  
  @media (max-height: 460px) {
    margin-top: -150px;
    transform: scale(0.6)
  }

  @media (max-height: 380px) {
    margin-top: -180px;
    transform: scale(0.5)
  }
`

const Help = () => {

  const DEFAULT_URL = "smart/help"

  const history = useHistory()
  const { t } = useTranslation()

  const { currentUser, setCurrentUser, helpClientEmail } = useContext(UserContext)
  const [formState, setFormState] = useState({ ...currentUser, description: null, module: null })
  const [loading, setLoading] = useState(false)
  const { registryLog } = useContext(UserContext)
  const [optionsAplications, setOptionsAplications] = useState([])

  useEffect(() => {
    registryLog('Page In', DEFAULT_URL)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const validateForm = () => {
    let valid = true
    if (!formState.description || formState.description.trim().length < 20) {
      toast(t('help.required.description'))
      valid = false
    }
    if (!formState.module || formState.module.trim() === '') {
      toast(t('help.required.module'))
      valid = false
    }
    return valid
  }

  const onSaveClick = async () => {
    if (validateForm()) {
      registryLog('send', DEFAULT_URL)
      setLoading(true)
      const result = await helpClientEmail(formState,)
      if (result === 'SUCCESS') {
        setCurrentUser(formState)
        history.goBack()
      }
      setLoading(false)
    }
  }
  console.log(currentUser)


  const optionsLanguages = useMemo(() => {
    return [
      { label: t('help.language-pt-br'), value: 'pt-BR' },
      { label: t('help.language-en'), value: 'en' },
      { label: t('help.language-es'), value: 'es' }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const optionsModules = useMemo(() => {
    return [
      { label: 'API', value: 'API' },
      { label: 'Collaborative Planning', value: 'Collaborative Planning' },
      { label: 'Databook', value: 'Databook' },
      { label: 'Field App', value: 'Field App' },
      { label: 'High Collapse', value: 'High Collapse' },
      { label: 'Performance Mapping', value: 'Performance Mapping' },
      { label: 'Probabilistic Design', value: 'Probabilistic Design' },
      { label: 'Tally Vision', value: 'Tally Vision' },
      { label: 'String DNA', value: 'String DNA' },
      { label: 'Traceability', value: 'Traceability' },
      { label: 'Tubular Life History', value: 'Tubular Life History' },
      { label: 'Tubular Yard Management', value: 'Tubular Yard Management' },
    ]
  }, [])

  const handleInputChange = (event) => {
    event.persist()
    setFormState(currentState => ({
      ...currentState,
      [event.target.name]: event.target.value
    }))
  }

  const handleSelectInput = name => value => {
    setFormState(currentState => ({
      ...currentState,
      [name]: value
    }))
  }

  useEffect(() => {
    const labels = currentUser.applications.map((label) => label.label)
    const newModules = optionsModules.filter((module) => labels.includes(module.label))
    setOptionsAplications(newModules)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  console.log(optionsAplications)

  return <FlexView flex="1" alignSelf="stretch" alignItems="center" backgroundColor="background" padding="40px">
    <HelpPage >
      <FlexView alignItems="center" justifyContent="center">
        <CardTitle>{t('help.pageTitle')}</CardTitle>
      </FlexView>
      <FlexView padding="1px 8px 0px">
        <ReadInput margin="0px" label={t('help.name')} readOnly={true} width="100%" name="name" value={formState.name} />
      </FlexView>
      <FlexView padding="1px 8px 0px">
        <ReadInput margin="0px" label={t('help.email')} readOnly={true} width="100%" name="email" value={formState.email} />
      </FlexView>
      <FlexView padding="1px 8px 0px" flexDirection="row">
        <Select margin="0px" label={t('help.language')} options={optionsLanguages} onChange={handleSelectInput('language')} value={formState.language} width="100%" />
      </FlexView>
      <FlexView padding="1px 8px 0px" flexDirection="row">
        <Select required margin="0px" label={t('help.module')} options={optionsAplications} onChange={handleSelectInput('module')} value={formState.module} width="100%" />
      </FlexView>
      <FlexView padding="1px 8px 0px">
        <TextArea required resize="vertical" margin="0px" label={t('help.description')} width="100%" style={{ heigth: '400px' }} onChange={handleInputChange} name="description" />
      </FlexView>
      <FlexView padding="1px 8px 0px">
      </FlexView>
      <FlexView margin="25px 8px 0px" flexDirection="row" alignItems="flex-end" justifyContent="flex-end">
        <Button alignSelf="flex-end" backgroundColor="secondary" color="white" style={{ marginRight: '10px' }} fontSize="big" onClick={() => history.goBack()} >
          {t('help.cancel')}
        </Button>
        <Button isLoading={loading} alignSelf="flex-end" backgroundColor="primary" color="white" fontSize="big" onClick={onSaveClick} >
          {t('help.send')}
        </Button>
      </FlexView>
    </HelpPage>
  </FlexView>
}

export default Help
