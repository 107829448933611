import React, { useEffect } from 'react'
import { FlexView } from 'components/common'
import styled from 'styled-components'

const OptionsWrapper = styled(FlexView)`
  font-family: 'Roboto';
  display: flex;
  visibility: ${({ open }) => open ? 'visible' : 'hidden'};
  opacity: ${({ open }) => open ? '1' : '0'};
  transform: ${({ popperTransform, open }) => `${popperTransform} ${open ? 'translateX(0)' : 'scale(0.9)'} !important`};
  flex-direction: column;
  margin: 8px;
  background: #FFFFFF;
  max-height: 300px;
  box-shadow: ${({ theme }) => theme.boxShadows.high };
  overflow-y: auto;
  border-radius: 8px;
  z-index: 999;
  transition: all 0.2s ease;
`

const Option = styled.div`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 32px);
  font-family: 'Roboto';
  font-size: ${({ theme }) => theme.fontSizes.big };
  user-select: none;
  min-width: 100px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray};
  transition: background-color 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.offWhite};
  }
`

const AppLinkSelect = React.forwardRef(({ style, scheduleUpdate, placement, isOpen, options, onSelect }, ref) => {
  useEffect(() => {
    scheduleUpdate()
  }, [isOpen, scheduleUpdate])

  const onOptionClick = value => () => onSelect(value)

  return <OptionsWrapper ref={ref} style={style} data-placement={placement} open={isOpen} popperTransform={style.transform}>
    {options.map(({ value, label }) => <Option key={value} value={value} onClick={onOptionClick(value)}>{label}</Option>)}
  </OptionsWrapper>
})

export default AppLinkSelect